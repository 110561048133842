import { mutatorAction } from 'satcheljs';

import { getStore, TSchedulerId } from '../store';

export const updateGameTimeRemainAction = mutatorAction(
  'updateGameTimeRemainAction',
  (schedulerId: TSchedulerId | undefined, timeRemain: number | undefined) => {
    if (!schedulerId) return;

    getStore().gameTimeRemainMap.set(schedulerId, timeRemain);
  }
);
