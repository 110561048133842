import { orchestrator } from 'satcheljs';

import { LoadingStore } from '@totopkg/shared-util-common';

import { getMyGameTicketsAction } from '../action';
import { getGameTicketsOfMeApi } from '../api';
import { updateGameHistoryBetTicketsAction } from '../mutator-action';
import { gameHistoryBetTicketsSelector } from '../selector';

orchestrator(getMyGameTicketsAction, async actionMessage => {
  const { params, callback } = actionMessage;
  const { schedulerId } = params;

  if (!schedulerId || !!gameHistoryBetTicketsSelector(schedulerId).length) return;

  LoadingStore.updateLoadingAction(true);

  try {
    const response = await getGameTicketsOfMeApi(params);
    updateGameHistoryBetTicketsAction(schedulerId, response);
    callback?.success?.(response);
  } catch (error) {
    callback?.error?.(error?.toString());
  } finally {
    LoadingStore.updateLoadingAction(false);
    callback?.finally?.();
  }
});
