import { IObservableArray, ObservableMap } from 'mobx';

import { ETableGameState, IBetLocationItem, IGameSchema, IHistoryBetTicket } from '../type';

export type TSchedulerId = string;

export type TTicketLocalId = string;

export type TBetLocation = string;

export type TResultValue = string;

export type TGameMessageType = 'DEFAULT' | 'WARNING' | 'ERROR' | 'SUCCESS' | 'BETTING_SUCCESS' | 'USER_NOT_BET';

export interface IGameMessage {
  message?: string;
  type?: TGameMessageType;
}

export interface IGameTableStore {
  gameHistoryBetTicketsMap: ObservableMap<TSchedulerId, IObservableArray<IHistoryBetTicket<TBetLocation, TResultValue>>>;
  gameSchemaMap: ObservableMap<TSchedulerId, IGameSchema[]>;
  gameTimeRemainMap: ObservableMap<TSchedulerId, number | undefined>;
  gameMessageMap: ObservableMap<TSchedulerId, IGameMessage | undefined>;

  userLocalBetLocationStackMap: ObservableMap<TSchedulerId, IObservableArray<IBetLocationItem>>;
  prevUserBettedLocationMap: ObservableMap<TSchedulerId, ObservableMap<TTicketLocalId, IBetLocationItem>>;
  currentRoundTicketMap: ObservableMap<TSchedulerId, IHistoryBetTicket<TBetLocation, TResultValue> | undefined>;
  gameStateMap: ObservableMap<TSchedulerId, ETableGameState>;
}
