import { TActionCallback } from '@totopkg/shared-util-common';

import {
  betAction,
  getGameSchemaAction,
  initGameAction,
  repeatUserBettedLocationAction,
  resetBettingRoundAction,
  unmountGameAction
} from '../action';
import {
  addUserLocalBetLocationAction,
  resetUserLocalBetLocationsAction,
  undoUserLocalBetLocationAction,
  updateGameMessageAction
} from '../mutator-action';
import {
  currentRoundTicketSelector,
  currentRoundTotalUserWonAmtSelector,
  gameBetLocationRatioSelector,
  gameHistoryBetTicketsSelector,
  gameMessageSelector,
  gameSchemasSelector,
  gameStateSelector,
  gameTimeRemainSelector,
  isUserCanBetSelector,
  isUserCanRepeatBetSelector,
  totalBetAmountByLocationSelector,
  totalUserLocalBetAmountSelector,
  totalUserLocalBetGroupByLocationSelector,
  userLocalBetLocationsSelector
} from '../selector';
import { TSchedulerId } from '../store';
import { ETableGameState, IBetLocationItem } from '../type';

export class BaseGameTableClass<BetLocationType extends string = string, ResultValueType extends string = string> {
  private static _baseInstance: BaseGameTableClass;
  protected drawResultTimeByMs: number;
  protected showResultTimeByMs: number;
  protected payoutTimeByMs: number;

  constructor() {
    this.drawResultTimeByMs = 3000;
    this.showResultTimeByMs = 5000;
    this.payoutTimeByMs = 1600;

    // ** bind this context
    this.initGame = this.initGame.bind(this);
    this.unmountGame = this.unmountGame.bind(this);

    this.getGameCurrentRoundResultGroupByLocation = this.getGameCurrentRoundResultGroupByLocation.bind(this);
    this.addUserLocalBetLocations = this.addUserLocalBetLocations.bind(this);
    this.undoUserLocalBetLocations = this.undoUserLocalBetLocations.bind(this);
    this.resetUserLocalBetLocations = this.resetUserLocalBetLocations.bind(this);
    this.bet = this.bet.bind(this);
    this.repeatBettedLocation = this.repeatBettedLocation.bind(this);
    this.resetBettingRound = this.resetBettingRound.bind(this);
    this.formatGameState = this.formatGameState.bind(this);
    this.updateGameMessage = this.updateGameMessage.bind(this);

    this.getBetLocationRatio = this.getBetLocationRatio.bind(this);
    this.getTotalBetAmountByLocation = this.getTotalBetAmountByLocation.bind(this);
    this.getUserLocationBetLocations = this.getUserLocationBetLocations.bind(this);
    this.getTotalUserLocalBetGroupByLocation = this.getTotalUserLocalBetGroupByLocation.bind(this);
    this.getCurrentRoundWonAmount = this.getCurrentRoundWonAmount.bind(this);
    this.getTotalUserLocalBetAmount = this.getTotalUserLocalBetAmount.bind(this);
    this.getGameSchema = this.getGameSchema.bind(this);
    this.getGameMessage = this.getGameMessage.bind(this);
    this.getGameState = this.getGameState.bind(this);
    this.getGameTimeRemain = this.getGameTimeRemain.bind(this);
    this.getGameHistoryBetTickets = this.getGameHistoryBetTickets.bind(this);
    this.getCurrentRoundResult = this.getCurrentRoundResult.bind(this);

    this.checkIsUserCanBet = this.checkIsUserCanBet.bind(this);
    this.checkIsUserCanRepeatBet = this.checkIsUserCanRepeatBet.bind(this);
  }

  public static baseInstance() {
    if (!this._baseInstance) {
      this._baseInstance = new BaseGameTableClass();
    }

    return this._baseInstance;
  }

  public initGame(...params: Parameters<typeof initGameAction>) {
    return initGameAction(...params);
  }

  public unmountGame(...params: Parameters<typeof unmountGameAction>) {
    return unmountGameAction(...params);
  }

  // ** implement it for each game
  public getGameCurrentRoundResultGroupByLocation(schedulerId: TSchedulerId | undefined): Map<BetLocationType, boolean> {
    throw '[Error] getGameCurrentRoundResultGroupByLocation: Child must implement this method';
  }

  public addUserLocalBetLocations(schedulerId: TSchedulerId | undefined, betLocations: IBetLocationItem[]) {
    addUserLocalBetLocationAction(schedulerId, betLocations);
  }

  public undoUserLocalBetLocations(schedulerId: TSchedulerId | undefined) {
    undoUserLocalBetLocationAction(schedulerId);
  }

  public resetUserLocalBetLocations(schedulerId: TSchedulerId | undefined) {
    resetUserLocalBetLocationsAction(schedulerId);
  }

  public bet(schedulerId: TSchedulerId | undefined, userBalance: number, callback?: TActionCallback) {
    const betItemsMap = totalUserLocalBetGroupByLocationSelector<BetLocationType>(schedulerId);
    const requiredBalance = totalUserLocalBetAmountSelector(schedulerId);

    if (userBalance < requiredBalance) {
      callback?.error?.('error.3002');
      return;
    }

    const betItems: IBetLocationItem<BetLocationType>[] = Array.from(betItemsMap?.values() || []);

    betAction(
      {
        schedulerId,
        data: { betItems, ccy: 'VND' },
        drawResultTime: this.drawResultTimeByMs,
        showResultTime: this.showResultTimeByMs,
        payoutTime: this.payoutTimeByMs
      },
      callback
    );
  }

  public resetBettingRound(schedulerId: TSchedulerId | undefined) {
    resetBettingRoundAction(schedulerId);
  }

  public formatGameState = (schedulerId: TSchedulerId, betLocation: BetLocationType | undefined) => {
    const gameState = this.getGameState(schedulerId);

    if (
      betLocation &&
      gameState === ETableGameState.SHOW_RESULT &&
      this.getGameCurrentRoundResultGroupByLocation(schedulerId).get(betLocation)
    ) {
      return 'WON';
    }

    return 'BETTING';
  };

  public getDrawResultTime() {
    return this.drawResultTimeByMs;
  }
  public getShowResultTime() {
    return this.showResultTimeByMs;
  }
  public getPayoutTime() {
    return this.payoutTimeByMs;
  }

  public getGameState(schedulerId: TSchedulerId | undefined) {
    return gameStateSelector(schedulerId);
  }

  public getGameTimeRemain(schedulerId: TSchedulerId | undefined) {
    return gameTimeRemainSelector(schedulerId);
  }

  public getGameMessage(schedulerId: TSchedulerId | undefined) {
    return gameMessageSelector(schedulerId);
  }

  public updateGameMessage(...params: Parameters<typeof updateGameMessageAction>) {
    return updateGameMessageAction(...params);
  }

  public getGameHistoryBetTickets(schedulerId: TSchedulerId | undefined) {
    return gameHistoryBetTicketsSelector(schedulerId);
  }

  public getCurrentRoundResult(schedulerId: TSchedulerId | undefined) {
    return currentRoundTicketSelector<BetLocationType, ResultValueType>(schedulerId)?.result;
  }

  public getBetLocationRatio(schedulerId: TSchedulerId | undefined, location: BetLocationType | undefined) {
    return gameBetLocationRatioSelector<BetLocationType>(schedulerId, location)?.ratioWin || 0;
  }

  public getTotalBetAmountByLocation(schedulerId: TSchedulerId | undefined, location: BetLocationType | undefined) {
    return totalBetAmountByLocationSelector<BetLocationType>(schedulerId, location);
  }

  public getUserLocationBetLocations(schedulerId: TSchedulerId | undefined) {
    return userLocalBetLocationsSelector<BetLocationType>(schedulerId);
  }

  public getTotalUserLocalBetGroupByLocation(schedulerId: TSchedulerId | undefined) {
    return totalUserLocalBetGroupByLocationSelector<BetLocationType>(schedulerId);
  }

  public getCurrentRoundWonAmount(schedulerId: TSchedulerId | undefined) {
    return currentRoundTotalUserWonAmtSelector(schedulerId);
  }

  public checkIsUserCanBet(schedulerId: TSchedulerId | undefined) {
    return isUserCanBetSelector(schedulerId);
  }
  public checkIsUserCanRepeatBet(schedulerId: TSchedulerId | undefined) {
    return isUserCanRepeatBetSelector(schedulerId);
  }

  public repeatBettedLocation(schedulerId: TSchedulerId | undefined) {
    repeatUserBettedLocationAction(schedulerId);
  }

  public getTotalUserLocalBetAmount(schedulerId: TSchedulerId | undefined) {
    return totalUserLocalBetAmountSelector(schedulerId);
  }

  public fetchGameSchemaBySchedulerId(schedulerId: TSchedulerId | undefined) {
    return getGameSchemaAction(schedulerId);
  }

  public getGameSchema(schedulerId: TSchedulerId | undefined) {
    return gameSchemasSelector(schedulerId);
  }
}
