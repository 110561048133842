import { BaseGameTableClass } from '../base-class';

export enum EDiceFace {
  ONE = 1,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX
}

export type TSicboBetLocationType =
  | 'SICBO_BIG'
  | 'SICBO_SMALL'
  | 'SICBO_EVEN'
  | 'SICBO_ODD'
  | 'SICBO_SINGLE_1'
  | 'SICBO_SINGLE_2'
  | 'SICBO_SINGLE_3'
  | 'SICBO_SINGLE_4'
  | 'SICBO_SINGLE_5'
  | 'SICBO_SINGLE_6'
  | 'SICBO_DOUBLE_1'
  | 'SICBO_DOUBLE_2'
  | 'SICBO_DOUBLE_3'
  | 'SICBO_DOUBLE_4'
  | 'SICBO_DOUBLE_5'
  | 'SICBO_DOUBLE_6'
  | 'SICBO_TRIPLE_1'
  | 'SICBO_TRIPLE_2'
  | 'SICBO_TRIPLE_3'
  | 'SICBO_TRIPLE_4'
  | 'SICBO_TRIPLE_5'
  | 'SICBO_TRIPLE_6'
  | 'SICBO_TRIPLE_ANY'
  | 'SICBO_TWO_DICE_1And2'
  | 'SICBO_TWO_DICE_1And3'
  | 'SICBO_TWO_DICE_1And4'
  | 'SICBO_TWO_DICE_1And5'
  | 'SICBO_TWO_DICE_1And6'
  | 'SICBO_TWO_DICE_2And3'
  | 'SICBO_TWO_DICE_2And4'
  | 'SICBO_TWO_DICE_2And5'
  | 'SICBO_TWO_DICE_2And6'
  | 'SICBO_TWO_DICE_3And4'
  | 'SICBO_TWO_DICE_3And5'
  | 'SICBO_TWO_DICE_3And6'
  | 'SICBO_TWO_DICE_4And5'
  | 'SICBO_TWO_DICE_4And6'
  | 'SICBO_TWO_DICE_5And6'
  | 'SICBO_SUM_4'
  | 'SICBO_SUM_5'
  | 'SICBO_SUM_6'
  | 'SICBO_SUM_7'
  | 'SICBO_SUM_8'
  | 'SICBO_SUM_9'
  | 'SICBO_SUM_10'
  | 'SICBO_SUM_11'
  | 'SICBO_SUM_12'
  | 'SICBO_SUM_13'
  | 'SICBO_SUM_14'
  | 'SICBO_SUM_15'
  | 'SICBO_SUM_16'
  | 'SICBO_SUM_17';

export type TSicboResultType = `${EDiceFace}${EDiceFace}${EDiceFace}`;

const DICES = ['1', '2', '3', '4', '5', '6'];

export class QuickSicbo extends BaseGameTableClass<TSicboBetLocationType, TSicboResultType> {
  private static _instance: QuickSicbo;

  private constructor() {
    super();
  }

  public static instance() {
    if (!this._instance) {
      this._instance = new QuickSicbo();
    }

    return this._instance;
  }

  public getGameCurrentRoundResultGroupByLocation = (roomId: string | undefined): Map<TSicboBetLocationType, boolean> => {
    const currentRoundResult = this.getCurrentRoundResult(roomId);

    const currentResultGroupByLocation = new Map<TSicboBetLocationType, boolean>();

    if (!currentRoundResult) return currentResultGroupByLocation;

    const diceResults = currentRoundResult.split('');

    const diceResultsSummary = diceResults.reduce((acc, dice) => (acc += Number(dice)), 0);

    // DICE OCCURRENCES CONDITIONS:

    let isStorm;
    DICES.forEach(dice => {
      const occurrenceOfDiceInResult = diceResults.filter(diceResult => diceResult === dice).length;
      switch (occurrenceOfDiceInResult) {
        case 1:
          currentResultGroupByLocation.set(`SICBO_SINGLE_${dice}` as TSicboBetLocationType, true);
          break;
        case 2:
          currentResultGroupByLocation.set(`SICBO_SINGLE_${dice}` as TSicboBetLocationType, true);
          currentResultGroupByLocation.set(`SICBO_DOUBLE_${dice}` as TSicboBetLocationType, true);
          break;
        case 3:
          isStorm = true;
          currentResultGroupByLocation.set(`SICBO_SINGLE_${dice}` as TSicboBetLocationType, true);
          currentResultGroupByLocation.set(`SICBO_DOUBLE_${dice}` as TSicboBetLocationType, true);
          currentResultGroupByLocation.set(`SICBO_TRIPLE_${dice}` as TSicboBetLocationType, true);
          currentResultGroupByLocation.set('SICBO_TRIPLE_ANY', true);
          break;

        default:
          break;
      }
    });

    if (!isStorm) {
      const sortedResults = diceResults.sort((a, b) => Number(a) - Number(b));
      const resultCombinations = [
        `${sortedResults[0]}${sortedResults[1]}`,
        `${sortedResults[0]}${sortedResults[2]}`,
        `${sortedResults[1]}${sortedResults[2]}`
      ];
      const resultUniqueCombinations = Array.from(new Set(resultCombinations));
      resultUniqueCombinations.forEach(combs => {
        const combsByNums = combs.split('');
        if (combsByNums[0] !== combsByNums[1]) {
          currentResultGroupByLocation.set(`SICBO_TWO_DICE_${combsByNums[0]}And${combsByNums[1]}` as TSicboBetLocationType, true);
        }
      });
    }

    // SUM CONDITIONS:

    if (!isStorm) {
      currentResultGroupByLocation.set(diceResultsSummary > 10 ? 'SICBO_BIG' : 'SICBO_SMALL', true);
      currentResultGroupByLocation.set(diceResultsSummary % 2 === 0 ? 'SICBO_EVEN' : 'SICBO_ODD', true);
    }

    if (diceResultsSummary >= 4 && diceResultsSummary <= 17) {
      currentResultGroupByLocation.set(`SICBO_SUM_${diceResultsSummary}` as TSicboBetLocationType, true);
    }

    return currentResultGroupByLocation;
  };
}
