import { observable } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getStore, TSchedulerId } from '../store';

export const resetUserLocalBetLocationsAction = mutatorAction(
  'resetUserLocalBetLocationsAction',
  (schedulerId: TSchedulerId | undefined) => {
    if (!schedulerId) return;

    getStore().userLocalBetLocationStackMap.set(schedulerId, observable.array([]));
  }
);
