import { mutatorAction } from 'satcheljs';

import { getStore, TSchedulerId } from '../store';
import { IHistoryBetTicket } from '../type';

export const updateCurrentRoundTicketAction = mutatorAction(
  'updateCurrentRoundTicketAction',
  (schedulerId: TSchedulerId | undefined, betTicket: IHistoryBetTicket | undefined) => {
    if (!schedulerId) return;

    getStore().currentRoundTicketMap.set(schedulerId, betTicket);
  }
);
