import { orchestrator } from 'satcheljs';

import { getGameSchemaAction, getMyGameTicketsAction, initGameAction } from '../action';
import { updateGameStateAction } from '../mutator-action';
import { ETableGameState } from '../type';

orchestrator(initGameAction, actionMessage => {
  const { params } = actionMessage;

  const { schedulerId } = params ?? {};

  if (!schedulerId) return;

  getMyGameTicketsAction(params);
  getGameSchemaAction(schedulerId);
  updateGameStateAction(schedulerId, ETableGameState.BET);
});
