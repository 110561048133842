import { observable } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getStore, TSchedulerId } from '../store';
import { IBetLocationItem } from '../type';

export const addUserLocalBetLocationAction = mutatorAction(
  'addUserLocalBetLocationAction',
  (schedulerId: TSchedulerId | undefined, betLocationItems: IBetLocationItem[]) => {
    if (!schedulerId) return;

    if (!getStore().userLocalBetLocationStackMap.has(schedulerId)) {
      getStore().userLocalBetLocationStackMap.set(schedulerId, observable.array(betLocationItems));
    } else {
      getStore()
        .userLocalBetLocationStackMap.get(schedulerId)
        ?.push(...betLocationItems);
    }
  }
);
