import floor from 'lodash/floor';

export function formatGameChip(chip: number | string | undefined) {
  if (!chip) return `0K`;
  const formatNumber = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 1,
  });

  if (typeof chip === 'string') {
    return !Number.isNaN(chip) && `${formatNumber.format(floor(parseInt(chip, 10) / 1000, 1))}K`;
  } else {
    return `${formatNumber.format(floor(chip / 1000, 1))}K`;
  }
}
