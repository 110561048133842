import { orchestrator } from 'satcheljs';

import { unmountGameAction } from '../action';
import { updateGameMessageAction, updateGameStateAction, updateGameTimeRemainAction } from '../mutator-action';
import { ETableGameState } from '../type';

orchestrator(unmountGameAction, actionMessage => {
  const { schedulerId } = actionMessage;

  if (!schedulerId) return;

  updateGameMessageAction(schedulerId, undefined);
  updateGameTimeRemainAction(schedulerId, undefined);
  updateGameStateAction(schedulerId, ETableGameState.BET);
});
