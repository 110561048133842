import { observable } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getStore, TSchedulerId } from '../store';
import { IHistoryBetTicket } from '../type';

export const updateGameHistoryBetTicketsAction = mutatorAction(
  'updateGameHistoryBetTicketsAction',
  (schedulerId: TSchedulerId | undefined, betTickets: IHistoryBetTicket[] | undefined, isPush?: boolean) => {
    if (!schedulerId || !betTickets) return;

    // bet tickets sort by ascending, new ticket will be pushing into existed tickets array

    if (isPush) {
      const storedTickets = getStore().gameHistoryBetTicketsMap.get(schedulerId);

      if (!storedTickets) {
        return;
      }

      // keep stored ticket has maximum 100 items
      if (storedTickets?.length >= 100) {
        storedTickets?.shift();
      }

      storedTickets.push(...betTickets);
    } else {
      // should be reverse tickets array here to keep the newest ticket at tail
      getStore().gameHistoryBetTicketsMap.set(schedulerId, observable.array(betTickets.reverse()));
    }
  }
);
