import { orchestrator } from 'satcheljs';

import { repeatUserBettedLocationAction } from '../action';
import { addUserLocalBetLocationAction, resetUserLocalBetLocationsAction, updatePrevUserBettedLocationsAction } from '../mutator-action';
import { prevUserBettedLocationsSelector } from '../selector';

orchestrator(repeatUserBettedLocationAction, actionMessage => {
  const { schedulerId } = actionMessage;

  if (!schedulerId) return;

  resetUserLocalBetLocationsAction(schedulerId);

  const prevUserBettedLocations = prevUserBettedLocationsSelector(schedulerId);
  addUserLocalBetLocationAction(schedulerId, Array.from(prevUserBettedLocations?.values() || []));
  updatePrevUserBettedLocationsAction(schedulerId, undefined);
});
