import { API } from '@totopkg/shared-util-api';

import { IGameSchema } from '../type';

export type TGetGameSchemaBySchedulerIdParams = {
  scheduler: string;
};

export const getGameSchemaApi = async (params: TGetGameSchemaBySchedulerIdParams) => {
  const response = await API.get<IGameSchema[]>('/minigame/gameSchemas/by-scheduler', { params });
  return response.data || [];
};
