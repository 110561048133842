import { TBetLocation, TSchedulerId } from '../store';
import { IBetLocationRatio } from '../type';
import { gameSchemasSelector } from './game-schemas.selector';

export const gameBetLocationRatioSelector = <BetLocationType extends TBetLocation = string>(
  schedulerId: TSchedulerId | undefined,
  location: BetLocationType | undefined
): IBetLocationRatio<BetLocationType> | undefined => {
  const gameSchemas = gameSchemasSelector(schedulerId);

  if (!schedulerId || !gameSchemas) return;

  return gameSchemas.find(schema => schema.id === location);
};
