import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

import { getStore, TSchedulerId, TTicketLocalId } from '../store';
import { IBetLocationItem } from '../type';

export const updatePrevUserBettedLocationsAction = mutatorAction(
  'updatePrevUserBettedLocationsAction',
  (schedulerId: TSchedulerId | undefined, bettedLocations: ObservableMap<TTicketLocalId, IBetLocationItem> | undefined) => {
    if (!schedulerId) return;

    if (!bettedLocations) {
      getStore().prevUserBettedLocationMap.delete(schedulerId);
    } else {
      getStore().prevUserBettedLocationMap.set(schedulerId, bettedLocations);
    }
  }
);
