import { IObservableArray } from 'mobx';

import { getStore, TBetLocation, TSchedulerId } from '../store';
import { IBetLocationItem } from '../type';

export const userLocalBetLocationsSelector = <BetLocationType extends TBetLocation = string>(schedulerId: TSchedulerId | undefined) => {
  return ((schedulerId && getStore().userLocalBetLocationStackMap.get(schedulerId)) || []) as IObservableArray<
    IBetLocationItem<BetLocationType>
  >;
};
