import { BaseGameTableClass } from '../base-class';
import { TSchedulerId } from '../store';

export type TRobBetLocationType =
  | 'ROB_EVEN'
  | 'ROB_ODD'
  | 'ROB_ONE_BLACK'
  | 'ROB_ONE_WHITE'
  | 'ROB_FOUR_BLACK'
  | 'ROB_FOUR_WHITE'
  | 'ROB_TWO_TWO'
  | 'ROB_BIG'
  | 'ROB_SMALL'
  | 'ROB_FOUR_ANY';

export type TRobResultType = '0' | '1' | '2' | '3' | '4';

export class QuickROB extends BaseGameTableClass<TRobBetLocationType, TRobResultType> {
  private static _instance: QuickROB;

  private constructor() {
    super();
  }

  public static instance() {
    if (!this._instance) {
      this._instance = new QuickROB();
    }

    return this._instance;
  }

  public getGameCurrentRoundResultGroupByLocation = (schedulerId: TSchedulerId | undefined): Map<TRobBetLocationType, boolean> => {
    const currentRoundResult = this.getCurrentRoundResult(schedulerId);

    const currentResultGroupByLocation = new Map<TRobBetLocationType, boolean>();

    switch (currentRoundResult) {
      case '0': {
        currentResultGroupByLocation.set('ROB_EVEN', true);
        currentResultGroupByLocation.set('ROB_FOUR_ANY', true);
        currentResultGroupByLocation.set('ROB_FOUR_WHITE', true);
        currentResultGroupByLocation.set('ROB_SMALL', true);
        break;
      }

      case '1': {
        currentResultGroupByLocation.set('ROB_ODD', true);
        currentResultGroupByLocation.set('ROB_ONE_BLACK', true);
        currentResultGroupByLocation.set('ROB_SMALL', true);
        break;
      }

      case '2': {
        currentResultGroupByLocation.set('ROB_EVEN', true);
        currentResultGroupByLocation.set('ROB_TWO_TWO', true);
        break;
      }

      case '3': {
        currentResultGroupByLocation.set('ROB_ODD', true);
        currentResultGroupByLocation.set('ROB_ONE_WHITE', true);
        currentResultGroupByLocation.set('ROB_BIG', true);
        break;
      }

      case '4': {
        currentResultGroupByLocation.set('ROB_FOUR_ANY', true);
        currentResultGroupByLocation.set('ROB_FOUR_BLACK', true);
        currentResultGroupByLocation.set('ROB_BIG', true);
        currentResultGroupByLocation.set('ROB_EVEN', true);
        break;
      }
      default:
        break;
    }

    return currentResultGroupByLocation;
  };
}
