import { orchestrator } from 'satcheljs';

import { resetBettingRoundAction } from '../action';
import { resetUserLocalBetLocationsAction, updatePrevUserBettedLocationsAction } from '../mutator-action';
import { totalUserLocalBetGroupByLocationSelector } from '../selector';

orchestrator(resetBettingRoundAction, actionMessage => {
  const { schedulerId } = actionMessage;

  if (!schedulerId) return;

  const bettedLocations = totalUserLocalBetGroupByLocationSelector(schedulerId);
  updatePrevUserBettedLocationsAction(schedulerId, bettedLocations);
  resetUserLocalBetLocationsAction(schedulerId);
});
