import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { TGetGameTicketsOfMeParams } from '../api';
import { IHistoryBetTicket } from '../type';

export const getMyGameTicketsAction = action(
  'getMyGameTicketsAction',
  (params: TGetGameTicketsOfMeParams, callback?: TActionCallback<IHistoryBetTicket[]>) => {
    return { params, callback };
  }
);
