import { ObservableMap } from 'mobx';

import { getStore, TSchedulerId } from '../store';
import { IBetLocationItem } from '../type';

export const prevUserBettedLocationsSelector = <BetLocationType extends string = string>(schedulerId: TSchedulerId | undefined) => {
  return ((schedulerId && getStore().prevUserBettedLocationMap.get(schedulerId)) || undefined) as
    | ObservableMap<BetLocationType, IBetLocationItem<BetLocationType>>
    | undefined;
};
