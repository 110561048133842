import { getStore, TBetLocation, TResultValue, TSchedulerId } from '../store';
import { IHistoryBetTicket } from '../type';

export const currentRoundTicketSelector = <BetLocationType extends TBetLocation = string, ResultValueType extends TResultValue = string>(
  schedulerId: TSchedulerId | undefined
) => {
  return (schedulerId ? getStore().currentRoundTicketMap.get(schedulerId) : undefined) as
    | IHistoryBetTicket<BetLocationType, ResultValueType>
    | undefined;
};
