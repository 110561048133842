import { mutatorAction } from 'satcheljs';

import { getStore, TSchedulerId } from '../store';
import { ETableGameState } from '../type';

export const updateGameStateAction = mutatorAction(
  'updateGameStateAction',
  (schedulerId: TSchedulerId | undefined, gameState: ETableGameState) => {
    if (!schedulerId) return;
    getStore().gameStateMap.set(schedulerId, gameState);
  }
);
