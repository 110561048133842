import { mutatorAction } from 'satcheljs';

import { getStore, TSchedulerId } from '../store';

export const undoUserLocalBetLocationAction = mutatorAction(
  'undoUserLocalBetLocationAction',
  (schedulerId: TSchedulerId | undefined, undoSteps = 1) => {
    if (!schedulerId) return;

    getStore()
      .userLocalBetLocationStackMap.get(schedulerId)
      ?.splice(-1 * undoSteps);
  }
);
