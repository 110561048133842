import { orchestrator } from 'satcheljs';

import { LoadingStore, MessageStore } from '@totopkg/shared-util-common';

import { getGameSchemaAction } from '../action';
import { getGameSchemaApi } from '../api';
import { updateGameSchemaAction } from '../mutator-action';
import { gameSchemasSelector } from '../selector';

orchestrator(getGameSchemaAction, async actionMessage => {
  const { schedulerId } = actionMessage;

  if (!schedulerId || gameSchemasSelector(schedulerId)) return;

  LoadingStore.updateLoadingAction(true);

  try {
    const response = await getGameSchemaApi({ scheduler: schedulerId });

    updateGameSchemaAction(schedulerId, response);
  } catch (error) {
    MessageStore.updateErrorAction(error as string);
  } finally {
    LoadingStore.updateLoadingAction(false);
  }
});
