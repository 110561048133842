import { API } from '@totopkg/shared-util-api';

import { TSchedulerId } from '../store';
import { IBetLocationItem, IHistoryBetTicket } from '../type';

export type TPostGameBySchedulerBetData = {
  betItems: IBetLocationItem[];
  ccy?: string;
};

export const betGameBySchedulerAPI = async (schedulerId: TSchedulerId, data: TPostGameBySchedulerBetData) => {
  const response = await API.post<IHistoryBetTicket>(`/minigame/games/${schedulerId}/bet`, data);
  return response.data;
};
