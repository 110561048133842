import { TBetLocation, TSchedulerId } from '../store';
import { totalUserLocalBetGroupByLocationSelector } from './total-user-local-bet-group-by-location.selector';

export const totalBetAmountByLocationSelector = <BetLocation extends TBetLocation = string>(
  schedulerId: TSchedulerId | undefined,
  location: BetLocation | undefined
) => {
  if (!schedulerId || !location) return 0;

  return totalUserLocalBetGroupByLocationSelector(schedulerId)?.get(location)?.betAmt || 0;
};
