import sum from 'lodash/sum';
import { ObservableMap } from 'mobx';
import { computedFn } from 'mobx-utils';

import { TSchedulerId } from '../store';
import { IBetLocationItem } from '../type';
import { userLocalBetLocationsSelector } from './user-local-bet-locations.selector';

export const totalUserLocalBetGroupByLocationSelector = computedFn(
  <BetLocationType extends string = string>(schedulerId: TSchedulerId | undefined) => {
    if (!schedulerId) return;

    const resultMap = new ObservableMap<BetLocationType, IBetLocationItem<BetLocationType>>();
    const userLocalBetLocations = userLocalBetLocationsSelector(schedulerId);

    userLocalBetLocations?.forEach((item) => {
      if (item.betLocation) {
        const _prev = resultMap.get(item.betLocation as BetLocationType);
        resultMap.set(item.betLocation as BetLocationType, {
          ..._prev,
          betAmt: sum([_prev?.betAmt, item.betAmt]),
          payoutAmt: sum([_prev?.payoutAmt, item.payoutAmt]),
          wonAmt: sum([_prev?.wonAmt, item.wonAmt]),
          betLocation: item.betLocation as BetLocationType,
        });
      }
    });

    return resultMap;
  }
);
