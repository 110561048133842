import { TBetLocation, TResultValue } from '../store';
import { IBetLocationItem } from './bet-location-item.type';

export enum EGameType {
  ROB = 'ROB',
  SICBO = 'SICBO'
}

export enum ETicketStatus {
  NEW = 'NEW',
  WON = 'WON',
  LOST = 'LOST',
  WARNING = 'WARNING'
}

export interface IBetRequest<BetLocationType extends TBetLocation = string> {
  localId: string;
  ccy?: string;
  betItems?: IBetLocationItem<BetLocationType>[];
}

export interface IBetTicket<BetLocationType extends TBetLocation = string> {
  localId?: string;
  id?: string;
  accountId?: string;
  username?: string;
  totalBetAmt?: number;
  betItems?: IBetLocationItem<BetLocationType>[];
}

export interface IHistoryBetTicket<BetLocationType extends TBetLocation = string, ResultValueType extends TResultValue = string>
  extends IBetTicket<BetLocationType> {
  totalBetUnit?: number;
  totalWonAmt?: number;
  totalWonUnit?: number;
  totalPayoutAmt?: number;
  result?: ResultValueType;
  scheduler?: string;
  schedulers?: string;
  schedulerId?: string;
  timeBet?: string;
  status?: ETicketStatus;
  issueId?: string;
  game?: EGameType;
}
