import { TSchedulerId } from '../store';
import { userLocalBetLocationsSelector } from './user-local-bet-locations.selector';

export const totalUserLocalBetAmountSelector = (schedulerId: TSchedulerId | undefined) => {
  const userLocalBetLocations = userLocalBetLocationsSelector(schedulerId);

  if (!schedulerId) return 0;

  return userLocalBetLocations.reduce((acc, betLocationItem) => (acc += betLocationItem.betAmt ?? 0), 0);
};
