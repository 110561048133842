import { action } from 'satcheljs';

import { TActionCallback } from '@totopkg/shared-util-common';

import { TPostGameBySchedulerBetData } from '../api';
import { TSchedulerId } from '../store';
import { IHistoryBetTicket } from '../type';

export const betAction = action(
  'betAction',
  (
    options: {
      schedulerId: TSchedulerId | undefined;
      data: TPostGameBySchedulerBetData | undefined;
      drawResultTime: number;
      showResultTime: number;
      payoutTime: number;
    },
    callback?: TActionCallback<IHistoryBetTicket>
  ) => {
    return { ...options, callback };
  }
);
