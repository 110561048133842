import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';

import { IGameTableStore } from './game-table.type';

export const initStore: IGameTableStore = {
  gameHistoryBetTicketsMap: new ObservableMap(),
  gameSchemaMap: new ObservableMap(),
  gameStateMap: new ObservableMap(),
  gameTimeRemainMap: new ObservableMap(),
  gameMessageMap: new ObservableMap(),

  userLocalBetLocationStackMap: new ObservableMap(),
  prevUserBettedLocationMap: new ObservableMap(),
  currentRoundTicketMap: new ObservableMap()
};

export const getStore = createStore('gameTableStore', initStore);
