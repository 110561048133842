import { orchestrator } from 'satcheljs';

import { betAction, resetBettingRoundAction } from '../action';
import { betGameBySchedulerAPI } from '../api';
import {
  updateCurrentRoundTicketAction,
  updateGameHistoryBetTicketsAction,
  updateGameMessageAction,
  updateGameStateAction,
  updateGameTimeRemainAction
} from '../mutator-action';
import { currentRoundTotalUserWonAmtSelector } from '../selector';
import { ETableGameState } from '../type';
import { formatGameChip } from '../util';

orchestrator(betAction, async actionMessage => {
  const { schedulerId, data, showResultTime, drawResultTime, payoutTime, callback } = actionMessage;

  if (!schedulerId || !data) return callback?.finally?.();

  try {
    updateGameStateAction(schedulerId, ETableGameState.DRAWING);

    const response = await betGameBySchedulerAPI(schedulerId, data);

    callback?.success?.(response);

    updateGameTimeRemainAction(schedulerId, drawResultTime);

    const timeoutId = setTimeout(() => {
      updateGameHistoryBetTicketsAction(schedulerId, [response], true);
      updateCurrentRoundTicketAction(schedulerId, response);
      updateGameStateAction(schedulerId, ETableGameState.SHOW_RESULT);
      updateGameTimeRemainAction(schedulerId, showResultTime);
      clearTimeout(timeoutId);

      const showResultTimeoutId = setTimeout(() => {
        updateGameStateAction(schedulerId, ETableGameState.PAYOUT);
        clearTimeout(showResultTimeoutId);

        const totalWonAmt = currentRoundTotalUserWonAmtSelector(schedulerId);

        if (totalWonAmt === 0) {
          updateGameMessageAction(schedulerId, {
            message: 'label.tieBet',
            type: 'DEFAULT'
          });
        } else if (totalWonAmt > 0) {
          updateGameMessageAction(schedulerId, {
            message: `+${formatGameChip(totalWonAmt)}`,
            type: 'SUCCESS'
          });
        } else {
          updateGameMessageAction(schedulerId, {
            message: `${formatGameChip(totalWonAmt)}`,
            type: 'ERROR'
          });
        }

        const payoutTimeoutId = setTimeout(() => {
          resetBettingRoundAction(schedulerId);
          updateCurrentRoundTicketAction(schedulerId, undefined);
          updateGameMessageAction(schedulerId, {
            message: 'message.startBet',
            type: 'WARNING'
          });

          updateGameStateAction(schedulerId, ETableGameState.BET);

          clearTimeout(payoutTimeoutId);
        }, payoutTime);

        clearTimeout(showResultTimeoutId);
      }, showResultTime);
    }, drawResultTime);
  } catch (error) {
    callback?.error?.(error?.toString());
    updateGameTimeRemainAction(schedulerId, 0);
    updateGameStateAction(schedulerId, ETableGameState.BET);
  } finally {
    callback?.finally?.();
  }
});
