import { API } from '@totopkg/shared-util-api';
import { TSearchParams } from '@totopkg/shared-util-crud';

import { IHistoryBetTicket } from '../type';

export type TGetGameTicketsOfMeParams = TSearchParams<
  IHistoryBetTicket & {
    betTimeFrom?: string;
    betTimeTo?: string;
  }
>;

export const getGameTicketsOfMeApi = async (params: TGetGameTicketsOfMeParams) => {
  const { schedulerId, schedulers, ...restParams } = params;

  const response = await API.get<IHistoryBetTicket[]>('/minigame/games/tickets/me', { params: { ...restParams, schedulers: schedulerId } });
  return response.data;
};
