import { mutatorAction } from 'satcheljs';

import { getStore, TSchedulerId } from '../store';
import { IGameSchema } from '../type';

export const updateGameSchemaAction = mutatorAction(
  'updateGameSchemaAction',
  (schedulerId: TSchedulerId | undefined, gameSchema: IGameSchema[] = []) => {
    if (!schedulerId) return;
    getStore().gameSchemaMap.set(schedulerId, gameSchema);
  }
);
