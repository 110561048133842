import { mutatorAction } from 'satcheljs';

import { getStore, IGameMessage, TSchedulerId } from '../store';

export const updateGameMessageAction = mutatorAction(
  'updateGameMessageAction',
  (schedulerId: TSchedulerId | undefined, message: IGameMessage | undefined) => {
    if (!schedulerId) return;

    getStore().gameMessageMap.set(schedulerId, message);
  }
);
